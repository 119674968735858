@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');


* {
  font-family: Titillium Web;
  color: #333;
}

.stock-link a {
  text-decoration: none;
}

.stock-link a:hover {
  color: #0067A5;
}

.navbar {
  padding: 15px;
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid #b6b6b6;
}

.logo a {
  color: #0067A5;
  text-decoration: none;
}

.navbar .links {
  margin-left: auto;
}

.links a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.links a:hover {
  color: #0067A5;
}

.content {
  max-width: 1000px;
  margin: 10px auto;
  padding: 10px;
}

.sidebar {
  width: 20%;
  float: right;
  border: 3px solid #007dca;
  border-radius: 15px;
  text-align: center;
  padding-bottom: 10px;
}

.sidebar input[type="text"] {
  font-size: 16px;
  border-radius: 0.4rem;
  padding: 0.2rem;
  max-width: 3.5rem;
}

.stock_content {
  border-radius: 15px;
  padding: 1rem 1rem 1rem 1rem;
  width: 100%;
  max-width: 100%;
  border: 4px solid #007dca;
  float: left;
}

.chart {
  float: left;
}

.ticker_history {
  display: inline-block;
  width: 100%;
}

.login {
  text-align: center;
  max-width: 600px;
  margin: 40px auto;
  padding: 100px;
}

table {
  width: 100%;
  max-width: 100%;
  text-align: left;
}

th, td {
  border-bottom: 1px solid #ddd;
  height: 40px;
}

.normal-button:disabled,
.login-handler button:disabled {
  opacity: 0.6;
}

.normal-button {
  padding: 0.4rem 1rem;
  transition-duration: 0.4s;
  background-color: #007dca;
  color: white;
  border-color: #333;
}

.normal-button:hover:not([disabled]),
.login-handler button:hover:not([disabled]) {
  background-color: #0067A5;
}

.normal-input[type="text"] {
  padding: 0.4rem;
}

.normal-button, .normal-input[type="text"] {
  font-size: 16px;
  border-radius: 0.4rem;
  margin: 0.5rem;
}

.login-handler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}

.login-handler section {
  width: 100%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0,0,0,0.4);
  border-radius: 0.5rem;
}

.login-handler form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

.login-handler button {
  transition-duration: 0.4s;
  background-color: #007dca;
  color: white;
  border-color: #333;
}

.login-handler input[type="text"],
.login-handler input[type="password"],
.login-handler button,
.login-handler textarea {
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

.login-handler label,
.login-handler button {
  margin-top: 1rem;
}

.login-handler button {
  padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}